import React, { useState, Fragment, useEffect } from "react";
import Gym1 from "./layout/image/gym1.png";
import Gym3 from "./layout/image/gym3.png";
import Bg1 from "./layout/image/bg1.png";
import Gym4 from "./layout/image/gym4.png";
import Logo from "./layout/image/logo.png";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Fade from "react-reveal/Fade";
import { Link as LinkScroll } from "react-scroll";
import { useInView } from "react-intersection-observer";
import Rotate from "react-reveal/Rotate";
import Zoom from "react-reveal/Zoom";
import Strenght from "@material-ui/icons/FitnessCenter";
import ExamplePdf from "../components/layout/documents/doc.pdf"
import {
  GiBoxingGlove,
  IoCall,
  SiGmail,
  IoLogoWhatsapp,
  HiDownload
} from "react-icons/all";
import { RiTeamFill } from "react-icons/all";
import Svg1 from "../components/layout/svg/Wave.svg";
import SvgMobile from "../components/layout/svg/WaveMobile.svg";
import Tada from "react-reveal/Tada";
import BgOrari from "./layout/svg/bgorari.svg";
import BgOrariMobile from "./layout/svg/bgorariMobile.svg";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Personal from "./layout/image/fotoaboutme.png";
const Gym = () => {
  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const { ref: ref1, inView: IsVisible1 } = useInView({
    /* Optional options */
    threshold: 0.35,
  });
  const { ref: ref2, inView: IsVisible2 } = useInView({
    /* Optional options */
    threshold: 0.35,
  });
  const { ref: ref3, inView: IsVisible3 } = useInView({
    /* Optional options */
    threshold: 0.35,
  });
  const { ref: ref4, inView: IsVisible4 } = useInView({
    /* Optional options */
    threshold: 0.05,
  });
  let Images = null;
  let BgWave = Svg1;
  let BgOra = BgOrari;
  if (width <= 767) {
    BgWave = SvgMobile;
    BgOra = BgOrariMobile;
  }
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => count + 1);
    }, 6500);
    return () => clearInterval(interval);
  }, []);

  if (count % 4 === 0) {
    Images = Bg1;
  } else if (count % 4 === 1) {
    Images = Gym3;
  } else if (count % 4 === 2) {
    Images = Gym1;
  } else {
    Images = Gym4;
  }

  const [IsOpen, setIsOpen] = useState(false);
  let widthMaps = 400;
  if (width <= 767) {
    widthMaps = width - 10;
  }
  let delay1 = 0;
  let delay2 = 0;
  let delay3 = 0;
  let delay4 = 100;
  if (width >= 768) {
    delay1 = 400;
    delay4 = 200;
    delay2 = 800;
    delay3 = 1200;
  }

  return (
    <div>
      <nav className=" backdrop-blur-md backdrop-filter p-2 mt-0 fixed w-full z-40 top-0">
        <div className="md:hidden">
          <div>
            <div className="container mx-auto flex flex-wrap items-center justify-between">
              <div className="flex justify-center md:justify-start ">
                <LinkScroll
                  to="first"
                  spy={true}
                  smooth={true}
                  className="cursor-pointer"
                >
                  <img src={Logo} className="w-20 h-20" alt="logo" />
                </LinkScroll>
              </div>
              <div className="hidden md:flex space-x-7">
                <LinkScroll
                  offset={-100}
                  to="primo"
                  spy={true}
                  smooth={true}
                  id="div1"
                  className={
                    IsVisible1
                      ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer"
                      : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer"
                  }
                >
                  ALLENAMENTI
                </LinkScroll>
                <LinkScroll
                  offset={-100}
                  to="secondo"
                  spy={true}
                  smooth={true}
                  id="div2"
                  className={
                    IsVisible2
                      ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer"
                      : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer"
                  }
                >
                  CHI SONO
                </LinkScroll>
                <LinkScroll
                  offset={-100}
                  to="terzo"
                  spy={true}
                  smooth={true}
                  id="div3"
                  className={
                    IsVisible3
                      ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer"
                      : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer"
                  }
                >
                  ORARI
                </LinkScroll>
                <LinkScroll
                  offset={-100}
                  to="quarto"
                  spy={true}
                  smooth={true}
                  id="div4"
                  className={
                    IsVisible4
                      ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer"
                      : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer"
                  }
                >
                  CONTATTI
                </LinkScroll>
              </div>
              <div className="flex pr-8 md:hidden">
                {IsOpen === true ? (
                  <div>
                    <Rotate spy={IsOpen}>
                      <button
                        onClick={() => setIsOpen(!IsOpen)}
                        className="text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-10 h-10"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                          />
                        </svg>
                      </button>
                    </Rotate>
                  </div>
                ) : (
                  <div>
                    <Rotate spy={IsOpen}>
                      <button
                        onClick={() => setIsOpen(!IsOpen)}
                        className="text-white "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-10 h-10"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                          />
                        </svg>
                      </button>
                    </Rotate>
                  </div>
                )}
              </div>
            </div>
            {IsOpen === true ? (
              <div>
                <div className="flex flex-col space-y-8 md:hidden  align-center text-center pb-8">
                  <Fade
                    top
                    delay={100}
                    duration={150}
                    spy={IsOpen}
                    opposite={true}
                    appear={true}
                    unmountOnExit={true}
                  >
                    <LinkScroll
                      offset={-100}
                      to="primo"
                      spy={true}
                      smooth={true}
                      data-nav="div1"
                      className={
                        IsVisible1
                          ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer pt-4"
                          : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer pt-4"
                      }
                    >
                      ALLENAMENTI
                    </LinkScroll>{" "}
                  </Fade>
                  <Fade
                    top
                    delay={200}
                    duration={450}
                    spy={IsOpen}
                    opposite={true}
                    appear={true}
                    unmountOnExit={true}
                  >
                    <LinkScroll
                      offset={-100}
                      to="secondo"
                      spy={true}
                      smooth={true}
                      data-nav="div2"
                      className={
                        IsVisible2
                          ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer "
                          : "font-palestra text-white font-bold  hover:text-red-600 cursor-pointer "
                      }
                    >
                      CHI SONO
                    </LinkScroll>{" "}
                  </Fade>
                  <Fade
                    top
                    delay={380}
                    duration={700}
                    spy={IsOpen}
                    opposite={true}
                    appear={true}
                    unmountOnExit={true}
                  >
                    <LinkScroll
                      offset={-100}
                      to="terzo"
                      spy={true}
                      smooth={true}
                      data-nav="div3"
                      className={
                        IsVisible3
                          ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer "
                          : "text-white font-palestra font-bold  hover:text-red-600 cursor-pointer "
                      }
                    >
                      ORARI
                    </LinkScroll>{" "}
                  </Fade>
                  <Fade
                    top
                    delay={500}
                    duration={900}
                    spy={IsOpen}
                    opposite={true}
                    appear={true}
                    unmountOnExit={true}
                  >
                    <LinkScroll
                      offset={-100}
                      to="quarto"
                      spy={true}
                      smooth={true}
                      data-nav="div4"
                      className={
                        IsVisible4
                          ? "font-palestra text-red-600 font-bold  hover:text-red-600 cursor-pointer "
                          : "text-white font-palestra font-bold  hover:text-red-600 cursor-pointer "
                      }
                    >
                      CONTATTI
                    </LinkScroll>{" "}
                  </Fade>
                </div>
                <Fade
                  cascade
                  top
                  delay={1000}
                  duration={1250}
                  spy={IsOpen}
                  opposite={true}
                  appear={true}
                  unmountOnExit={true}
                >
                  <div className="pb-8 flex flex-rows justify-center items-center">
                    <a
                      href="https://instagram.com/swangallitv?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white  font-semibold hover:text-red-600 mr-4 "
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://www.facebook.com/SwanGalli"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white font-semibold hover:text-red-600 mr-4"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href="https://twitter.com/swangalli?s=20"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white  font-semibold hover:text-red-600 mr-4"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.youtube.com/user/SwanGalliTV"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white font-semibold hover:text-red-600 mr-4"
                    >
                      <YouTubeIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/swan-galli-20556720b"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white  font-semibold hover:text-red-600"
                    >
                      <LinkedInIcon />
                    </a>
                  </div>
                </Fade>
              </div>
            ) : (
              <Fragment />
            )}
          </div>
        </div>

        <div className="hidden md:flex container mx-auto  flex-wrap items-center justify-between">
          <div className="flex justify-center md:justify-start ">
            <LinkScroll
              to="first"
              spy={true}
              smooth={true}
              className="cursor-pointer"
            >
              <img src={Logo} className="w-20 h-20" alt="logo" />
            </LinkScroll>
          </div>
          <div className="hidden md:flex space-x-7">
            <LinkScroll
              to="primo"
              offset={-100}
              spy={true}
              smooth={true}
              data-nav="div1"
              className={
                IsVisible1
                  ? "text-red-600 font-bold  hover:text-red-600 cursor-pointer font-palestra"
                  : "text-white font-bold  hover:text-red-600 cursor-pointer font-palestra"
              }
            >
              ALLENAMENTI
            </LinkScroll>
            <LinkScroll
              to="secondo"
              offset={-100}
              spy={true}
              smooth={true}
              data-nav="div2"
              className={
                IsVisible2
                  ? "text-red-600 font-bold  hover:text-red-600 cursor-pointer font-palestra"
                  : "text-white font-bold  hover:text-red-600 cursor-pointer font-palestra"
              }
            >
              CHI SONO
            </LinkScroll>
            <LinkScroll
              to="terzo"
              offset={-100}
              spy={true}
              smooth={true}
              data-nav="div3"
              className={
                IsVisible3
                  ? "text-red-600 font-bold  hover:text-red-600 cursor-pointer font-palestra"
                  : "text-white font-bold  hover:text-red-600 cursor-pointer font-palestra"
              }
            >
              ORARI
            </LinkScroll>
            <LinkScroll
              to="quarto"
              offset={-100}
              spy={true}
              smooth={true}
              data-nav="div4"
              className={
                IsVisible4
                  ? "text-red-600 font-bold  hover:text-red-600 cursor-pointer font-palestra"
                  : "text-white font-bold  hover:text-red-600 cursor-pointer font-palestra"
              }
            >
              CONTATTI
            </LinkScroll>
          </div>
          <div>
            <a
              href="https://instagram.com/swangallitv?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white  font-semibold hover:text-red-600 mr-4"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/SwanGalli"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white font-semibold hover:text-red-600 mr-4"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/swangalli?s=20"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white  font-semibold hover:text-red-600 mr-4"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/user/SwanGalliTV"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white font-semibold hover:text-red-600 mr-4"
            >
              <YouTubeIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/swan-galli-20556720b"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white  font-semibold hover:text-red-600"
            >
              <LinkedInIcon />
            </a>
          </div>
        </div>
      </nav>

      <div id="first" className="w-full h-full relative bg-grigio">
        <img
          src={Images}
          alt="palestra"
          className=" transform ease-in-out bg-center object-cover w-full h-screen"
        />
        <Fade bottom opposite={true}>
          <h6 className="absolute  mt-4 bottom-1/3 left-10 md:left-1/4 text-3xl  md:text-5xl cursor-default font-bold font-palestra text-white">
            IL MIO SCOPO
          </h6>
        </Fade>
        <Fade bottom delay={340} opposite={true}>
          <h6 className="absolute left-10 bottom-1/3  -mb-14  md:mt-20 md:left-1/4  cursor-default  font-palestra text-3xl md:text-5xl font-extrabold text-white">
            IL TUO OBBIETTIVO!
          </h6>
        </Fade>

        <Fade bottom delay={600} opposite={true}>
          <button className=" hover:bg-red-700 hover:text-white inline-flex space-x-4 absolute px-7 py-2 bottom-1/3 left-10 md:left-1/4 md:mt-44 -mb-32  bg-red-600 text-gray-200 border-red-600  hover:border-red-800 font-extralight text-lg items-center ">
            <a href="https://chat.whatsapp.com/LGZ8O4H37bj4OEDzX05qIf">
              Contattaci
            </a>{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="animate-pulse mt-0.5 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </Fade>
      </div>

      <div
        id="primo"
        ref={ref1}
        className=" w-full bg-black grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-4 justify-center items-center"
      >
        <Zoom top>
          <div className="flex flex-col  justify-center items-center pt-8 pb-8 md:pt-16 md:pb-16">
            <p className="text-red-600 p-6 bg-grigio rounded-full cursor-default">
              <Strenght fontSize="large" />
            </p>
            <p className="text-gray-100 font-semibold text-lg pt-10 cursor-default font-palestra">
              FORZA E CONDIZIONAMENTO
            </p>
            <p className="text-gray-500 pt-5 text-center p-2 cursor-default font-palestra font-light w-96">
              Aumenta la tua <b className="text-gray-300">massa muscolare</b> e allenati in modo sicuro con
              allenatori esperti che ti seguiranno su ogni esercizio
            </p>
          </div>
        </Zoom>
        <Zoom top delay={delay1}>
          <div className="flex flex-col  justify-center items-center pt-8 pb-8 md:pt-16 md:pb-16">
            <p className="text-red-600 p-6 bg-grigio rounded-full cursor-default">
              <GiBoxingGlove className="h-8 w-8" />
            </p>
            <p className="text-gray-100 font-semibold text-lg pt-10 cursor-default font-palestra">
              CONCETTI DI BOXE
            </p>
            <p className="text-gray-500 pt-5 text-center p-2 cursor-default font-palestra font-light w-96">
              Impara in totale sicurezza la nobile arte della boxe con
              allenatori esperti che ti insegneranno ogni segreto della
              disciplina
            </p>
          </div>
        </Zoom>

        <Zoom top delay={delay2}>
          <div className="flex flex-col  justify-center items-center pt-8 pb-8 md:pt-16 md:pb-16">
            <p className="text-red-600 p-6 bg-grigio rounded-full cursor-default">
              <FastfoodIcon fontSize="large" />
            </p>
            <p className="text-gray-100 font-semibold text-lg pt-10 cursor-default font-palestra">
              DEFINIZIONE E DIMAGRIMENTO
            </p>
            <p className="text-gray-500 pt-5 text-center p-2 cursor-default font-palestra font-light w-96">
              Se il tuo scopo è perdere peso, definire il tuo fisico o
              tonificarlo affidati a noi con una vasta scelta di esercizi creati
              su misura per te
            </p>
          </div>
        </Zoom>

        <Zoom top delay={delay3}>
          <div className="flex flex-col  justify-center items-center pt-8 pb-16 md:pt-16 ">
            <p className="text-red-600 p-6 bg-grigio rounded-full cursor-default">
              <RiTeamFill className="h-8 w-8" />
            </p>
            <p className="text-gray-100 font-semibold text-lg pt-10 cursor-default font-palestra">
              SPORT DI SQUADRA & INDIVIDUALI
            </p>
            <p className="text-gray-500 pt-5 text-center cursor-default font-palestra font-light w-96">
              Migliora le tue <b className="text-gray-300">performance</b> per sport Individuali e a Squadre con
              esercizi mirati per il tuo sport e creati su misura per le tue
              necessità
            </p>
          </div>
        </Zoom>
      </div>

      <div
        ref={ref2}
        id="secondo"
        className="flex relative w-screen h-screen  z-20"
      >
        <img alt="background" src={BgWave} className="h-screen w-full  " />
        <div className="flex flex-row space-x-4 w-full  justify-center top-9 xl:top-28 md:top-12 z-10 absolute">
          <h1 className="font-extrabold md:text-4xl text-3xl text-transparent bg-clip-text bg-gradient-to-r from-red-400  to-red-700 cursor-default font-palestra">
            CHI{" "}
          </h1>
          <h1 className="text-white font-extrabold md:text-4xl text-3xl cursor-default font-palestra">
            SONO{" "}
          </h1>
          <Tada forever delay={300} duration={3000}>
            <h1 className="text-white font-extrabold md:text-6xl text-5xl -mt-3.5 cursor-default">
              ?
            </h1>
          </Tada>
        </div>
        <div className="absolute flex flex-col md:flex-row w-full h-full justify-evenly items-center top-12 md:top-0">
          <Fade left delay={100}>
            <img
              src={Personal}
              alt="personal"
              className="object-cover h-96 md:h-2/3 rounded-3xl xl:mt-28 cursor-not-allowed"
            />
          </Fade>
          <div className="flex flex-col w-11/12  items-center md:items-start md:w-1/3 md:mt-24 ">
            <Fade top delay={300}>
              <h1
                className="font-palestra font-semibold text-center pb-12 text-3xl  text-transparent bg-clip-text bg-gradient-to-r from-red-500  to-red-800 cursor-default
                          transform hover:scale-150 transition duration-500 w-48 -mt-7 md:-mt-0"
              >
                SWAN GALLI
              </h1>
            </Fade>
            <Fade cascade duration={3000} delay={500}>
              {" "}
              <p
                className="font-palestra text-gray-300 text-center md:text-left cursor-default hover:text-white
                            md:transform hover:scale-125 md:transition md:duration-500 overflow-y-scroll h-60 -mt-9 md:-mt-0 p-1"
              >
                Sono Swan Galli benvenuta/o nella mia pagina! Fin da ragazzo mi
                sono avvicinato allo sport, in particolare al pugilato: la
                "Nobile Arte" mi ha aiutato a formarmi nel carattere, perché
                pretende disciplina, rigore, costanza e determinazione. Con il
                tempo, mi sono reso conto che per raggiungere i miei obbiettivi,
                il mio corpo doveva superare alcuni limiti. Mi sono quindi
                avvicinato al mondo del fitness, sperimentando su di me tecniche
                ed esercizi che mi aiutassero a superare queste barriere. Ho
                approfondito le conoscenze tecniche e le competenze necessarie
                per crescere e migliorare, senza mai accontentarmi della
                mediocrità. Oggi, metto a vostra disposizione la mia esperienza
                e la mia professionalità: lavoreremo insieme per i tuoi
                obiettivi; migliorare il tuo corpo e le tue prestazioni,
                sentirti bene con te stesso, raggiungere il tuo equilibrio
                psico-fisico!
              </p>
            </Fade>
          </div>
        </div>
      </div>

      <div
        id="terzo"
        ref={ref3}
        className=" flex flex-col justify-center items-center relative w-full h-full "
      >
        <img
          src={BgOra}
          alt="bgorari"
          className="w-full h-1/3 overflow-hidden"
        />
        <h1 className="absolute text-white font-extrabold text-4xl md:text-5xl top-16 md:top-6 lg:top-16 font-palestra text-center">
          ORARIO SU APPUNTAMENTO
        </h1>

        <div className="absolute flex flex-row space-x-8 top-1/4 md:top-24 lg:top-1/3 lg:-mt-28">
          <div className="space-y-8 lg:mt-16 text-right  md:mt-0 ">
            <Zoom bottom>
              <h6 className="text-white text-xl font-medium font-palestra">
                Lunedì :
              </h6>
            </Zoom>
            <Zoom bottom delay={100}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Martedì :
              </h6>
            </Zoom>
            <Zoom bottom delay={200}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Mercoledì :
              </h6>
            </Zoom>
            <Zoom bottom delay={300}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Giovedì :
              </h6>
            </Zoom>
            <Zoom bottom delay={400}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Venerdì :
              </h6>
            </Zoom>
            <Zoom bottom delay={500}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Sabato :
              </h6>
            </Zoom>
            <Zoom bottom delay={600}>
              <h6 className="text-white text-xl font-medium font-palestra">
                Domenica :
              </h6>
            </Zoom>
          </div>
          <div className="space-y-8  lg:mt-16 md:mt-0">
            <Zoom bottom>
              <div className="flex flex-row ">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={100}>
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={200}>
              {" "}
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={300}>
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={400}>
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={500}>
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <h6 className="text-red-500 text-xl font-palestra pr-1 font-semibold">
                    7
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 30
                  </h6>
                </div>
                <h6 className="text-xl font-palestra font-extrabold text-white px-3">
                  -
                </h6>
                <div className="flex flex-row">
                  <h6 className="text-xl font-palestra text-red-500 pr-1 font-semibold">
                    21
                  </h6>
                  <h6 className="text-xl font-palestra text-white font-semibold">
                    {" "}
                    : 00
                  </h6>
                </div>
              </div>
            </Zoom>
            <Zoom bottom delay={600}>
              <h6 className="text-xl font-palestra text-red-500 font-semibold ml-8">
                CHIUSO
              </h6>
            </Zoom>
          </div>
        </div>
      </div>

      <div
        id="quarto"
        ref={ref4}
        className="grid lg:grid-rows-1 lg:grid-cols-3 grid-rows-3 grid-cols-1 w-full h-full relative bg-grigio justify-around items-center"
      >
        <div className="w-full h-full text-center items-center justify-center  md:py-0 ">
          <div className="flex flex-row space-x-5 text-center justify-center items-center pb-14 md:pb-0">
            <h6 className="text-3xl md:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-red-500  to-red-800 cursor-default font-extrabold pt-16 font-palestra">
              DOVE
            </h6>
            <h6 className="text-3xl md:text-5xl text-white font-extrabold cursor-default pt-16 font-palestra">
              SIAMO
            </h6>
            <Tada forever delay={300} duration={3000}>
              <h6 className="text-4xl md:text-6xl text-white font-extrabold  pt-16">
                ?
              </h6>
            </Tada>
          </div>
          <div className="md:pt-20  justify-center items-center  flex flex-col text-center md:space-y-5">
            <Fade duration={3000} delay={delay4 + 50}>
              <iframe
                title="maps"
                width={widthMaps}
                height="300"
                src="https://maps.google.com/maps?q=Via%20Ca%20savorgan%209A&t=&z=17&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
              />
            </Fade>
            <Fade left delay={delay4}>
              <a
                href="https://goo.gl/maps/7tDgnD9hitHUGRXH9"
                className="text-white font-bold hover:underline md:pb-16 pt-6 md:pt-0 font-palestra"
              >
                {" "}
                Via Cà Savorgnan 9A, 30172, Venezia
              </a>
            </Fade>
          </div>
        </div>
        <div className="w-full h-auto md:h-full justify-center items-center text-center md:ml-6">
              <h6 className="text-3xl md:text-5xl font-extrabold cursor-default text-white md:pt-16  pb-9 font-palestra">
                  MODULISTICA
              </h6>
              <div className="md:pt-20 space-y-12 justify-center items-center  mt-12 mr-6">
                <div className="flex flex-row space-x-4 justify-center">
                  <a href={ExamplePdf}
                    download="MODULO ADESIONE PERCORSO DI ALLENAMENTO PERSONALIZZATO"
                    target="_blank"
                    rel="noopener noreferrer"><HiDownload className="text-red-600 h-9 w-9 -mt-1" /></a>
                  <a
                    href={ExamplePdf}
                    download="MODULO ADESIONE PERCORSO DI ALLENAMENTO PERSONALIZZATO"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="text-white hover:underline text-lg font-palestra" >Modulo di adesione</button>
                  </a>
                </div>
                
              </div>
        </div>
        <div className="w-full h-auto md:h-full justify-center items-center text-center">
          <h6 className="text-3xl md:text-5xl font-extrabold cursor-default text-white md:pt-16  pb-9 font-palestra">
            CONTATTI
          </h6>
          <div className="flex w-full justify-around ">
            <Zoom bottom cascade delay={delay4}>
              <div className="md:pt-20 space-y-12 justify-center items-center  mt-12 mr-6">
                <div className="flex flex-row space-x-4 justify-center">
                  <a href="mailto: swangalli@live.it">
                    <SiGmail className="text-red-600 h-9 w-9 -mt-1" />
                  </a>
                  <a
                    href="mailto: swangalli@live.it"
                    className="text-white hover:underline text-lg font-palestra"
                  >
                    swangalli@live.it
                  </a>
                </div>
                <div className="flex flex-row space-x-4">
                  <a href="https://wa.me/393493800930">
                    <IoLogoWhatsapp className="w-9 h-9 text-red-600 -mt-1" />
                  </a>
                  <a
                    href="https://wa.me/393493800930"
                    className="text-white hover:underline text-lg font-palestra"
                  >
                    WhatsApp
                  </a>
                </div>
                <div className="flex flex-row space-x-4">
                  <a href="tel: 3493800930">
                    <IoCall className="text-red-600 h-9 w-9 -mt-1" />
                  </a>
                  <a
                    href="tel: 3493800930"
                    className="text-white hover:underline text-lg font-palestra"
                  >
                    3493800930
                  </a>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-300 h-auto md:h-20 bg-grigio flex flex-col md:flex-row  justify-center items-center md:space-x-12  text-center">
        <h6 className="text-white pt-5 md:pt-0 text-center font-palestra">
          © 2022 Swan Lab. All rights reserved
        </h6>
        <div className="flex flex-row space-x-1 text-center pt-3 md:pt-0">
          <h6 className="text-white text-center font-palestra">Developed by</h6>
          <a
            href="https://it.linkedin.com/in/leonardo-tibasco-526139217"
            className="text-white font-semibold  text-center font-palestra "
          >
            Leonardo Tibasco
          </a>
        </div>
        <div className="flex flex-row space-x-1 text-center md:pt-0 md:pb-0 pt-3 pb-5">
          <h6 className="text-white  text-center font-palestra">P.iva :</h6>
          <a
            href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
            className=" text-center text-red-600 hover:underline font-semibold font-palestra"
          >
            {" "}
            03919900278
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gym;
