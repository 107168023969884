import React, { Fragment, useState } from "react";
import LogoNavbar from "./image/logo.png";
import ImageAboutMe from "./image/fotoaboutme.png";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Transition } from "@headlessui/react";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link as LinkScroll } from "react-scroll";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Slide from "react-reveal/Slide";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <div className="hidden md:block">
        {/* Video e sticky navbar e landing  */}
        <div className="relative flex items-center justify-center h-screen  overflow-hidden bg-sfondodesktop bg-center bg-no-repeat w-full  ">
          <div className="z-30  bg-transparent font-sans w-full min-h-screen ">
            <div>
              <div className="container mx-auto ">
                <div className="flex items-center justify-between mt-12">
                  <div className="flex-shrink-0 flex items-center ">
                    <Link to="/">
                      <img
                        src={LogoNavbar}
                        className="hidden md:block h-16 w-16"
                        alt="Logo"
                      />
                    </Link>
                  </div>

                  <div className="hidden sm:flex sm:items-center">
                    <LinkScroll
                      className="text-yellow-500 text-base font-semibold hover:text-yellow-600 mr-4 cursor-pointer"
                      spy={true}
                      smooth={true}
                      to="about-me"
                    >
                      Su di me
                    </LinkScroll>
                    <LinkScroll
                      className="text-yellow-500 text-base font-semibold hover:text-yellow-600 mr-4 cursor-pointer"
                      spy={true}
                      smooth={true}
                      to="sponsor"
                    >
                      Sponsor
                    </LinkScroll>
                    <LinkScroll
                      className="text-yellow-500 text-base font-semibold hover:text-yellow-600 mr-4 cursor-pointer"
                      spy={true}
                      smooth={true}
                      to="piani"
                    >
                      Programmi
                    </LinkScroll>
                    <LinkScroll
                      className="text-yellow-500 text-base font-semibold hover:text-yellow-600 mr-4 cursor-pointer"
                      spy={true}
                      smooth={true}
                      to="contatti"
                    >
                      Contatti
                    </LinkScroll>
                  </div>

                  <div className="hidden sm:flex sm:items-center">
                    <a
                      href="https://instagram.com/swangallitv?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500  font-semibold hover:text-yellow-600 mr-4"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://www.facebook.com/SwanGalli"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500 font-semibold hover:text-yellow-600 mr-4"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href="https://twitter.com/swangalli?s=20"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500  font-semibold hover:text-yellow-600 mr-4"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.youtube.com/user/SwanGalliTV"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500 font-semibold hover:text-yellow-600 mr-4"
                    >
                      <YouTubeIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/swan-galli-20556720b"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500  font-semibold hover:text-yellow-600"
                    >
                      <LinkedInIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" z-30  absolute  mt-28">
            <Fade left cascade delay={350}>
              <span className="mb-4">
                <h1 className="cursor-default  md:mr-12 lg:mr-72 font-sos  text-7xl font-extrabold text-white -mt-32">
                  IL MIO SCOPO,
                </h1>
                <h1 className="cursor-default  md:mr-12 font-sos lg:mr-72 text-7xl font-extrabold text-white">
                  IL TUO OBBIETTIVO!
                </h1>
              </span>
            </Fade>
            <Fade right cascade delay={350}>
              <span>
                <h1 className="cursor-default mt-3   text-yellow-500 text-4xl font-light">
                  Swan Galli - Personal Trainer
                </h1>
              </span>
            </Fade>
            <div className="flex flex-row mt-12 ml-4 space-x-8">
              <LinkScroll
                spy={true}
                smooth={true}
                to="sponsor"
                className="cursor-pointer transform hover:scale-110 motion-reduce:transform-none bg-transparent justify-center
                   text-yellow-500 hover:text-yellow-600 rounded-lg py-3.5 px-6 transition duration-300 font-semibold ring-2 ring-yellow-500 hover:font-medium hover:ring-yellow-600"
              >
                Sponsor
              </LinkScroll>
              <LinkScroll
                className="cursor-pointer transform hover:scale-110 motion-reduce:transform-none bg-yellow-500  justify-center
                   text-gray-200 rounded-lg py-3.5 px-6 transition duration-300 font-semibold hover:text-gray-300 ring-2  ring-yellow-500 hover:bg-yellow-600 hover:font-medium"
                spy={true}
                smooth={true}
                to="piani"
              >
                Programmi
              </LinkScroll>
            </div>
          </div>
        </div>

        {/* fine parte desktop */}
      </div>

      {/* Inizio parte mobile */}

      <div className="block md:hidden">
        <div className="relative flex items-center justify-center h-screen  overflow-hidden bg-sfondohome bg-center bg-no-repeat w-full  bg-cover">
          <div className="sticky z-40 bg-transparent font-sans w-full min-h-screen">
            <nav className="  bg-yellow-500 ">
              <div className=" container  max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex-shrink-0">
                    <img
                      className="hidden md:block h-16 w-16"
                      src={LogoNavbar}
                      alt="Logo"
                    />
                  </div>

                  <div>
                    <a
                      href="https://instagram.com/swangallitv?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black  font-semibold mr-4"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://www.facebook.com/SwanGalli"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black  font-semibold mr-4"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href="https://twitter.com/swangalli?s=20"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black  font-semibold  mr-4"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.youtube.com/user/SwanGalliTV"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black  font-semibold  mr-4"
                    >
                      <YouTubeIcon />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/swan-galli-20556720b"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black   font-semibold "
                    >
                      <LinkedInIcon />
                    </a>
                  </div>

                  <div className="-mr-2 flex md:hidden">
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      type="button"
                      className="bg-tranparent inline-flex items-center justify-center p-2 rounded-md text-black  focus:outline-none focus:ring-2   focus:ring-black"
                      aria-controls="mobile-menu"
                      aria-expanded="false"
                    >
                      {!isOpen ? (
                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {(ref) => (
                  <div className="md:hidden" id="mobile-menu">
                    <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                      <LinkScroll
                        spy={true}
                        smooth={true}
                        to="about-me-mobile"
                        onClick={() => setIsOpen(!isOpen)}
                        className="hover:bg-yellow-700 text-black block px-3 py-2 rounded-md text-base font-medium"
                      >
                        Su di me
                      </LinkScroll>
                      <LinkScroll
                        spy={true}
                        smooth={true}
                        to="sponsor-mobile"
                        onClick={() => setIsOpen(!isOpen)}
                        className="hover:bg-yellow-700 text-black   block px-3 py-2 rounded-md text-base font-medium"
                      >
                        Sponsor
                      </LinkScroll>

                      <LinkScroll
                        spy={true}
                        smooth={true}
                        to="piani"
                        onClick={() => setIsOpen(!isOpen)}
                        className="hover:bg-yellow-700 text-black  block px-3 py-2 rounded-md text-base font-medium"
                      >
                        Programmi
                      </LinkScroll>

                      <LinkScroll
                        spy={true}
                        smooth={true}
                        to="contatti-mobile"
                        onClick={() => setIsOpen(!isOpen)}
                        className="hover:bg-yellow-700 text-black  block px-3 py-2 rounded-md text-base font-medium"
                      >
                        Contatti
                      </LinkScroll>
                    </div>
                  </div>
                )}
              </Transition>
            </nav>
          </div>

          {/* landing page per telefono */}
          <div className=" absolute  block md:hidden overflow-hidden ">
            <Fade left cascade delay={350}>
              <span className="mb-4 text-center">
                <h1 className="sm:ml-8 md:ml-12 font-sos  text-5xl font-extrabold text-white ">
                  IL MIO SCOPO,
                </h1>
                <h1 className="sm:mr-4 md:mr-12 font-sos  mb-3 text-5xl font-extrabold text-white">
                  IL TUO OBBIETTIVO!
                </h1>
              </span>
            </Fade>
            <Fade left cascade delay={350}>
              <span className="text-center">
                <h1 className="sm:mt-4 font-Raleway font-medium text-yellow-500 text-2xl  ">
                  Swan Galli - Personal Trainer
                </h1>
              </span>
            </Fade>
          </div>
          <div className="z-40 text-center absolute  block md:hidden flex-row  mt-72 space-x-8">
            <LinkScroll
              spy={true}
              smooth={true}
              to="sponsor-mobile"
              className="cursor-pointer transform hover:scale-110 motion-reduce:transform-none bg-transparent justify-center
                   text-yellow-500 hover:text-yellow-600 rounded-lg py-3.5 px-8 transition duration-300 text-sm font-semibold ring-2 ring-yellow-500 "
            >
              Sponsor
            </LinkScroll>
            <LinkScroll
              className="cursor-pointer transform hover:scale-110 motion-reduce:transform-none bg-yellow-500  justify-center
                      
                   text-gray-200 rounded-lg py-3.5  px-6 transition duration-300 font-semibold hover:text-gray-300 ring-2   ring-yellow-500 hover:bg-yellow-600 hover:font-medium"
              spy={true}
              smooth={true}
              to="piani"
            >
              Programmi
            </LinkScroll>
          </div>
        </div>
      </div>

      {/* about me desktop */}

      <div
        className="bg-black hidden md:flex flex-row item-center justify-center"
        id="about-me"
      >
        <Fade left duration={2000}>
          <div className="items-center  justify-center flex    w-3/6  ">
            <img src={ImageAboutMe} alt="Swan Galli" className=" my-10  " />
          </div>
        </Fade>
        <div className="w-3/6 mt-24 mb-24  ">
          <div className="ml-24 mr-24 flex flex-col justify-center items-center  h-full">
            <Fade exit={true} duration={3500}>
              <h6 className="text-base text-white font-bold text-center">
                SU DI ME{" "}
              </h6>
            </Fade>
            <Fade exit={true} duration={3500}>
              <h1 className="text-4xl text-white  font-extrabold text-center">
                {" "}
                SWAN GALLI
              </h1>
            </Fade>
            <Fade bottom exit={true} duration={2300}>
              <p className="text-center text-gray-200 text-lg  -ml-14  mt-10">
                Sono Swan Galli benvenuta/o nella mia pagina! Fin da ragazzo mi
                sono avvicinato allo sport, in particolare al pugilato: la
                "Nobile Arte" mi ha aiutato a formarmi nel carattere, perché
                pretende disciplina, rigore, costanza e determinazione. Con il
                tempo, mi sono reso conto che per raggiungere i miei obbiettivi,
                il mio corpo doveva superare alcuni limiti. Mi sono quindi
                avvicinato al mondo del fitness, sperimentando su di me tecniche
                ed esercizi che mi aiutassero a superare queste barriere. Ho
                approfondito le conoscenze tecniche e le competenze necessarie
                per crescere e migliorare, senza mai accontentarmi della
                mediocrità. Oggi, metto a vostra disposizione la mia esperienza
                e la mia professionalità: lavoreremo insieme per i tuoi
                obiettivi; migliorare il tuo corpo e le tue prestazioni,
                sentirti bene con te stesso, raggiungere il tuo equilibrio
                psico-fisico!
              </p>
            </Fade>
          </div>
        </div>
      </div>

      {/* about me mobile */}
      <div
        className=" bg-black  flex md:hidden flex-col  justify-center  h-full "
        id="about-me-mobile"
      >
        <div className="mt-12 overflow-hidden">
          <Slide left>
            <h6 className="mb-2 text-base text-white font-bold text-center ">
              SU DI ME{" "}
            </h6>
          </Slide>
        </div>

        <div className="mb-4 overflow-hidden">
          <Slide right>
            <h1 className="text-4xl text-white  font-extrabold text-center">
              {" "}
              SWAN GALLI
            </h1>
          </Slide>
        </div>
        <div className="overflow-hidden">
          <Fade bottom cascade exit={true} duration={1500}>
            <img src={ImageAboutMe} alt="Swan Galli" className=" px-6 " />
          </Fade>
        </div>
        <div className="overflow-hidden">
          <p className="text-center inline-block align-middle text-gray-200  py-16 px-6 ">
            <Fade bottom exit={true} duration={1000}>
              Sono Swan Galli benvenuta/o nella mia pagina! Fin da ragazzo mi
              sono avvicinato allo sport, in particolare al pugilato: la "Nobile
              Arte" mi ha aiutato a formarmi nel carattere, perché pretende
              disciplina, rigore, costanza e determinazione. Con il tempo, mi
              sono reso conto che per raggiungere i miei obbiettivi, il mio
              corpo doveva superare alcuni limiti. Mi sono quindi avvicinato al
              mondo del fitness, sperimentando su di me tecniche ed esercizi che
              mi aiutassero a superare queste barriere. Ho approfondito le
              conoscenze tecniche e le competenze necessarie per crescere e
              migliorare, senza mai accontentarmi della mediocrità. Oggi, metto
              a vostra disposizione la mia esperienza e la mia professionalità:
              lavoreremo insieme per i tuoi obiettivi; migliorare il tuo corpo e
              le tue prestazioni, sentirti bene con te stesso, raggiungere il
              tuo equilibrio psico-fisico!
            </Fade>
          </p>
        </div>
      </div>
      {/* sponsor desktop */}
      <div
        className="hidden md:flex bg-sfondo bg-center bg-no-repeat w-full h-full bg-cover  flex-col "
        id="sponsor"
      >
        <Fade bottom big>
          <div className="w-full  text-center mt-14">
            <h1 className="text-6xl  font-bold text-transparent bg-clip-text bg-gradient-to-t from-yellow-600  to-yellow-700 ">
              I MIEI SPONSOR :
            </h1>
          </div>
        </Fade>
        <div className="flex flex-row w-full mb-64">
          <Fade left>
            <div className="w-3/6 text-center mt-44 space-y-1">
              <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-600  to-yellow-400">
                <a
                  href="https://www.yamamotonutrition.com/ita/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YamamotoNutrition &rarr;
                </a>
              </h1>

              <h6 className="text-gray-400 font-semibold">
                e con il codice coupon:
              </h6>
              <h1 className="text-transparent bg-clip-text bg-gradient-to-b from-yellow-500  to-yellow-700 text-4xl font-extrabold">
                SWAN10
              </h1>
              <div className="flex flex-row justify-center items-end space-x-2">
                <h6 className="text-white text-2xl font-extrabold"> 10% </h6>
                <h6 className="text-gray-400 mb-0.5 font-semibold">
                  di sconto
                </h6>
              </div>
            </div>
          </Fade>

          <Fade right>
            <div className="w-3/6 text-center mt-44 space-y-1">
              <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-t from-yellow-400  to-yellow-600 mb-3">
                <a
                  href="https://vitastrong.it/it/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vitastrong &rarr;
                </a>
              </h1>

              <h6 className="text-gray-400 font-semibold">
                e con il codice sconto:
              </h6>
              <h1 className="text-transparent bg-clip-text bg-gradient-to-b from-yellow-500  to-yellow-700 text-4xl font-extrabold">
                SWAN10
              </h1>
              <div className="flex flex-row justify-center items-end space-x-2">
                <h6 className="text-white text-2xl font-extrabold"> 10% </h6>
                <h6 className="text-gray-400 mb-0.5 font-semibold">
                  di sconto
                </h6>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {/* sponsor mobile*/}
      <div
        className="flex md:hidden  bg-sfondo bg-center bg-no-repeat w-full h-full  bg-cover  flex-col overflow-x-hidden"
        id="sponsor-mobile"
      >
        <Fade duration={1500}>
          <div className="text-center  mt-14">
            <h1 className="text-4xl  font-bold text-transparent bg-clip-text bg-gradient-to-t from-yellow-600  to-yellow-700 ">
              I MIEI SPONSOR :
            </h1>
          </div>
        </Fade>
        <div className="flex flex-col pb-24">
          <Fade left duration={1500}>
            <div className="text-center mt-24 space-y-1">
              <h1 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-600  to-yellow-400">
                <a
                  href="https://www.yamamotonutrition.com/ita/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YamamotoNutrition &rarr;
                </a>
              </h1>
              <h6 className="text-gray-300 font-semibold text-sm">
                e con il codice coupon:
              </h6>
              <h1 className="text-transparent bg-clip-text bg-gradient-to-b from-yellow-500  to-yellow-700 text-2xl font-extrabold">
                SWAN10
              </h1>
              <div className="flex flex-row justify-center items-end space-x-2">
                <h6 className="text-white text-xl font-extrabold"> 10% </h6>
                <h6 className="text-gray-300 mb-0.5 font-semibold text-sm">
                  di sconto
                </h6>
              </div>
            </div>
          </Fade>

          <Fade right duration={1500}>
            <div className="text-center mt-16   space-y-1">
              <h1 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-t from-yellow-400  to-yellow-600">
                <a
                  href="https://vitastrong.it/it/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vitastrong &rarr;
                </a>
              </h1>

              <h6 className="text-gray-300 font-semibold text-sm">
                e con il codice sconto:
              </h6>
              <h1 className="text-transparent bg-clip-text bg-gradient-to-b from-yellow-500  to-yellow-700 text-2xl font-extrabold">
                SWAN10
              </h1>
              <div className="flex flex-row justify-center items-end space-x-2">
                <h6 className="text-white text-xl font-extrabold"> 10% </h6>
                <h6 className="text-gray-300 mb-0.5 font-semibold text-sm">
                  di sconto
                </h6>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {/* piani di allenamento desktop */}
      <section className=" px-4 pt-24 pb-12 bg-black" id="piani">
        <div className="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <h1 className="text-center block pb-2 mb-5 text-4xl font-extrabold leading-none tracking-normal text-transparent md:text-6xl md:tracking-tight bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-700">
            I miei piani di allenamento.
          </h1>
          <p className="px-0 mb-10 text-lg text-gray-400 md:text-xl lg:px-24 text-center ">
            Sono completamente personalizzati per ogni utente!.
          </p>
        </div>

        <div className="w-full mx-auto  2xl:px-32">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-16 lg:grid-cols-4 sm:gap-8">
            <Fade left delay={900}>
              <div className="border-0 shadow-none card rounded-xl md:border">
                <div className="flex flex-col justify-between p-6 border-b border-gray-200">
                  <p className="mb-1 text-lg font-semibold text-yellow-400">
                    Alpha - principiante{" "}
                  </p>
                  <p className="my-2 text-4xl font-bold text-gray-900">
                    € Su richiesta{" "}
                  </p>
                  <p className="text-sm text-gray-500">
                    per persone senza esperienza
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: swangalli@live.it"
                    className="w-full mt-6 btn bg-yellow-400 btn-lg transform hover:scale-110 motion-reduce:transform-none transition duration-300"
                  >
                    Inizia subito &rarr;
                  </a>
                </div>
                <ul className="flex flex-col flex-grow p-6 space-y-3">
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-400"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700"> video tutorial</span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-400"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">supporto telefonico</span>
                  </li>
                </ul>
              </div>
            </Fade>
            <Fade left delay={650}>
              <div className="border-0  shadow-none card rounded-xl md:border">
                <div className="flex flex-col justify-between p-6 border-b border-gray-200">
                  <p className="mb-1 text-lg font-semibold text-yellow-500">
                    Bravo - esordiente
                  </p>
                  <p className="my-2 text-4xl font-bold text-gray-900">
                    € Su richiesta{" "}
                  </p>
                  <p className="text-sm text-gray-500">
                    per persone con minima esperienza
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: swangalli@live.it"
                    className="w-full mt-6 btn bg-yellow-500 btn-lg transform hover:scale-110 motion-reduce:transform-none transition duration-300"
                  >
                    Inizia subito &rarr;
                  </a>
                </div>
                <ul className="flex flex-col flex-grow p-6 space-y-3">
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700"> video tutorial</span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">supporto telefonico</span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">
                      seduta online o one-to-one
                    </span>
                  </li>
                </ul>
              </div>
            </Fade>

            <Fade left delay={300}>
              <div className="border-0  shadow-none card rounded-xl md:border">
                <div className="flex flex-col justify-between p-6 border-b border-gray-200">
                  <div>
                    <div className="flex items-center justify-between">
                      <p className="mb-1 text-lg font-semibold text-yellow-600">
                        Delta - intermedio
                      </p>
                      <span className="badge bg-primary-light text-primary">
                        Più popolare
                      </span>
                    </div>
                    <p className="my-2 text-4xl font-bold text-gray-900">
                      € Su richiesta{" "}
                    </p>
                    <p className="text-sm text-gray-500">
                      per persone con moderata esperienza
                    </p>
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: swangalli@live.it"
                    className="w-full mt-6 btn bg-yellow-600 btn-lg transform hover:scale-110 motion-reduce:transform-none transition duration-300"
                  >
                    Inizia subito &rarr;
                  </a>
                </div>
                <ul className="flex flex-col flex-grow p-6 space-y-3">
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">video tutorial</span>
                  </li>
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-600"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">supporto telefonico</span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-600"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">
                      seduta online o one-to-one
                    </span>
                  </li>
                </ul>
              </div>
            </Fade>
            <Fade left>
              <div className="border-0  shadow-none card rounded-xl md:border">
                <div className="flex flex-col justify-between p-6 border-b border-gray-200">
                  <p className="mb-1 text-lg font-semibold text-yellow-700">
                    Echo - esperto
                  </p>
                  <p className="my-2 text-4xl font-bold text-gray-900">
                    € Su richiesta{" "}
                  </p>
                  <p className="text-sm text-gray-500">per persone esperte</p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: swangalli@live.it"
                    className="w-full mt-6 btn bg-yellow-700 btn-lg transform hover:scale-110 motion-reduce:transform-none transition duration-300"
                  >
                    Inizia subito &rarr;
                  </a>
                </div>
                <ul className="flex flex-col flex-grow p-6 space-y-3">
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-700"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700"> video tutorial </span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-700"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">supporto telefonico</span>
                  </li>

                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-700"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">
                      seduta online o one-to-one
                    </span>
                  </li>
                  <li className="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="flex-none w-4 h-4 mt-1 mr-2 text-yellow-700"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-gray-700">
                      sedute face-to-face personalizzate
                    </span>
                  </li>
                </ul>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* footer e contatti vari desktop */}
      <div className="hidden md:block" id="contatti">
        <footer
          id="footer"
          className="relative z-50 bg-gray-200  item-center justify-center "
        >
          <div className=" border-t border-b py-16 border-gray-400 max-w-6xl mx-auto">
            <div className="mx-auto container px-4 xl:px-12 2xl:px-4 items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="w-full justify-between mb-16 lg:mb-0 flex ">
                  <div className="w-full ml-40   ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="tel:349-380-0930"
                      >
                        <PhoneIcon />
                      </a>
                    </div>
                    <div className="-ml-5 mt-4 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="-ml-8 "
                        href="tel:349-380-0930"
                      >
                        +39 (349)3800930
                      </a>
                    </div>
                  </div>

                  <div className="w-full  ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/393493800930"
                      >
                        <WhatsAppIcon />
                      </a>
                    </div>
                    <div className="w-1/12 mt-4 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="-ml-4 "
                        href="https://wa.me/393493800930"
                      >
                        {" "}
                        Whatsapp
                      </a>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto: swangalli@live.it"
                      >
                        <MailIcon />
                      </a>
                    </div>
                    <div className="mt-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="-ml-8"
                        href="mailto: swangalli@live.it"
                      >
                        swangalli@live.it
                      </a>
                    </div>
                  </div>
                  <div className="w-full  ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://goo.gl/maps/7tDgnD9hitHUGRXH9"
                      >
                        <LocationOnIcon />
                      </a>
                    </div>
                    <div className="mt-4 ">
                      <a
                        className="-ml-6 "
                        href="https://goo.gl/maps/7tDgnD9hitHUGRXH9"
                      >
                        Via Cà Savorgnan 9A
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pt-2 flex flex-row justify-center items-center max-w-6xl mx-auto space-x-8">
            <p className="cursor-default mt-6 text-xs lg:text-sm leading-none text-gray-900 ">
              {" "}
              © 2021 Swan Galli. All rights reserved{" "}
            </p>
            <p className="cursor-default mt-6 text-xs lg:text-sm leading-none text-gray-900">
              Developed by
              <a
                href="mailto:leotiba02@gmail.com?subject=Creazione sito Web personalizzato"
                className="mt-6 text-xs lg:text-sm leading-none text-gray-900 font-semibold "
              >
                {" "}
                Leonardo Tibasco
              </a>
            </p>

            <p className="mt-6 text-xs lg:text-sm leading-none text-gray-900">
              P.iva:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
              >
                03919900278
              </a>
            </p>
          </div>
        </footer>
      </div>

      {/* footer e contatti vari da telefono*/}
      <div className=" md:hidden w-full " id="contatti-mobile">
        <footer
          id="footer"
          className=" z-50 bg-gray-200  item-center justify-center "
        >
          <div className=" border-t border-b pt-8 border-gray-400  mx-auto">
            <div className="mx-auto container px-4 ">
              <div className="flex item-center justify-center">
                <div className="justify-between mb-16 lg:mb-0 flex flex-col space-y-8">
                  <div className=" ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white ml-8">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="tel:349-380-0930"
                      >
                        <PhoneIcon />
                      </a>
                    </div>
                    <div className=" mt-4 -ml-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=""
                        href="tel:349-380-0930"
                      >
                        +39 (349)3800930
                      </a>
                    </div>
                  </div>

                  <div className=" ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white ml-8">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/393493800930"
                      >
                        <WhatsAppIcon />
                      </a>
                    </div>
                    <div className="w-1/12 mt-4 ml-5 ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=""
                        href="https://wa.me/393493800930"
                      >
                        {" "}
                        Whatsapp
                      </a>
                    </div>
                  </div>

                  <div className=" ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white ml-8">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto: swangalli@live.it"
                      >
                        <MailIcon />
                      </a>
                    </div>
                    <div className="mt-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className=""
                        href="mailto: swangalli@live.it"
                      >
                        swangalli@live.it
                      </a>
                    </div>
                  </div>
                  <div className=" ">
                    <div className="rounded-full h-12 w-12 flex items-center justify-center bg-white ml-8">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://goo.gl/maps/7tDgnD9hitHUGRXH9"
                      >
                        <LocationOnIcon />
                      </a>
                    </div>
                    <div className="mt-4 -ml-4">
                      <a
                        className=" "
                        href="https://goo.gl/maps/7tDgnD9hitHUGRXH9"
                      >
                        Via Cà Savorgnan 9A
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pt-2 flex flex-col justify-center items-center">
            <div className="">
              <p className="cursor-default mt-6 text-sm text-gray-900 ">
                {" "}
                © 2021 Swan Galli. All rights reserved
              </p>
            </div>
            <div className="">
              <p className="cursor-default mt-6 text-sm   text-gray-900">
                Developed by
                <a
                  href="mailto:leotiba02@gmail.com "
                  className="mt-6 text-sm  text-gray-900 font-semibold "
                >
                  {" "}
                  Leonardo Tibasco
                </a>
              </p>
            </div>
            <div className="">
              <p className="mt-6  text-sm  text-gray-900">
                P.iva:{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
                >
                  03919900278
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  );
}

export default Navbar;
