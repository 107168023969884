import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "../src/components/layout/Landing";
import LandingPage from "/Users/leonardotibasco/Desktop/galliProject-Develop/src/components/LandingPage.js";
import GymPage from "/Users/leonardotibasco/Desktop/galliProject-Develop/src/components/GymPage.js";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={GymPage} />
          <Route path="*" component={GymPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
