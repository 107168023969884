import React from "react";
import { Helmet } from "react-helmet";
import Gym from "./Gym.js";
const GymPage = () => {
  return (
    <div>
      <Helmet>
        <title>{"Swan Lab"}</title>
      </Helmet>
      <Gym />
    </div>
  );
};

export default GymPage;
